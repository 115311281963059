
import { defineComponent, ref } from 'vue';

import AppDatePicker from '@/components/stateless/AppDatePicker.vue';
import AppBadge from '@/components/stateless/AppBadge.vue';

export default defineComponent({
  name: 'DatePickers',

  components: { AppDatePicker, AppBadge },

  setup() {

    const date = ref<string>('');
    const dateTime = ref<string>('');

    return { date, dateTime };
  }
});
