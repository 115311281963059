<template>
  <div class="text-primary text-3xl text-center p-7 border-b border-grey-fp-30">DatePickers</div>
  <div class="overflow-y-auto">
    <div class="w-350">
      <div class="py-10 mb-18">
        <p class="text-1xl text-grey-fp-50 mb-20">Sizes</p>
        <AppDatePicker
          v-model="date"
          type="month"
          format="MM/YYYY"
          class="mb-18"
        />
      </div>
      <div class="py-10 mb-18">
        <p class="text-1xl text-grey-fp-50 mb-20">Disabled</p>
        <div class="flex items-center">
          <AppDatePicker
            v-model="date"
            type="month"
            format="MM/YYYY"
            class="mb-18"
            disabled
          />
        </div>
      </div>
      <div class="py-10 mb-18">
        <p class="text-1xl text-grey-fp-50 mb-20">Error</p>
        <div class="flex items-center">
          <AppDatePicker
            v-model="date"
            type="month"
            format="MM/YYYY"
            class="mb-18"
            error="Error message"
          />
        </div>
      </div>
      <div class="py-10 mb-18">
        <p class="text-1xl text-grey-fp-50 mb-20">Types</p>
        <AppDatePicker
          v-model="date"
          label="Month/Year"
          type="month"
          format="MM/YYYY"
          class="mb-18"
        />
        {{ dateTime }}
        <AppDatePicker
          v-model="dateTime"
          label="Date"
          type="datetime"
          class="mb-18"
        />
      </div>

      <div class="py-10 mb-18">
        <p class="text-1xl text-grey-fp-50 mb-20">Custom Trigger</p>
        <AppDatePicker
          v-model="date"
          class="mb-18 max-w-200"
        > 
          <template #trigger="{ openDatepicker }">
            <AppBadge 
              icon-name="calendar" 
              :icon-size="14"
              :content="`Target Date: ${date && $filters.date(date, 'MM/DD')}`" 
              class="text-md text-lucky-orange cursor-pointer" 
              custom-classes="inline-flex"
              @click="openDatepicker"
            />
          </template>
        </AppDatePicker>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppDatePicker from '@/components/stateless/AppDatePicker.vue';
import AppBadge from '@/components/stateless/AppBadge.vue';

export default defineComponent({
  name: 'DatePickers',

  components: { AppDatePicker, AppBadge },

  setup() {

    const date = ref<string>('');
    const dateTime = ref<string>('');

    return { date, dateTime };
  }
});
</script>